@use '../../importable/colors.scss' as colors;

.app-uppercase {
  text-transform: uppercase;
  font-size: 0.85em;
  letter-spacing: 0.05em;
}

.app-label {
  color: #000;
  border-radius: 0.25em;
  padding: 0.25em 0.58em;
  color: white;
}

.app-label__good {
  @extend .app-uppercase, .app-label;
  background-color: rgba(colors.$good-status, 0.8);
}

.app-label__warning {
  @extend .app-uppercase, .app-label;
  background-color: rgba(colors.$warning-status, 0.8);
}

.app-label__alarm {
  @extend .app-uppercase, .app-label;
  background-color: rgba(colors.$alarm-status, 0.8);
}

.app-label__disabled {
  @extend .app-uppercase, .app-label;
  background-color: #777;
}

.app-label__neutral {
  @extend .app-uppercase, .app-label;
  background-color: rgba(colors.$neutral-status, 0.8);
}

$text-color: var(--text-color);

$none-status: var(--text-primary-color);
$good-status: #6bae4f;
$warning-status: #f0ab34;
$severe-warning-status: #e67b33;
$alarm-status: #c9433e;
$neutral-status: #78a9bd;

// Dark bg
$dark-background: #2c2c2c;

@use '../importable/colors.scss' as colors;

// ---------------------------------
// 				Custom theme
// ---------------------------------
@import "./theme/dark_theme.scss";

// Text color
.color-status-none {
  color: colors.$none-status;
}

.color-status-good {
  color: colors.$good-status;
}

.color-status-warning {
  color: colors.$warning-status;
}

.color-status-alarm {
  color: colors.$alarm-status;
}

.bg-status-good {
  background-color: colors.$good-status;
}

.bg-status-warning {
  background-color: colors.$warning-status;
}

.bg-status-alarm {
  background-color: colors.$alarm-status;
}

.bg-card {
  background-color: map-get($map: $mat-dark-theme-background, $key: card);
}

/* ------------------------------------------------------ */

body {
  background-color: #2c2c2c;
  color: var(--text-primary-color);

  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;

}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

span.spacer {
  flex: 1 1 auto;
}

span.spacer-small {
  margin: 5px;
}

.main-container {
  width: 100%;
  display: flex;
  height: 100%;
  padding-top: 10px;
}

.minimized {
  max-height: 0px;
  overflow-y: hidden;
}

// --------------------------------------
//   Site header
// --------------------------------------
hr {
  background-color: rgba(255, 255, 255, 0.12);
}

.site-header {
  background-repeat: no-repeat;
  background-size: fill;
  background-position: center;
  display: flex;
}

.site-header .title {
  width: 100%;
  text-align: center;
  // border: 1px solid white;
  background-color: inherit;
  margin: auto;
}

.site-header .title h2 {
  font-size: 36px;
}

.site-header .title h3 {
  font-size: 24px;
}

.site-header .title h4 {
  font-size: 16px;
}

.site-header .title p {
  font-weight: lighter;
}

// --------------------------------------
//   Customer logo
// --------------------------------------
.customer-company-logo {
  // background-color: rgba(red, 0.2);
  // position: fixed;
  // top: 0;
  // right: 0;

  // margin-right: 24px;

  color: rgba(white, 0.8);
  width: auto;
  height: 100%;
  max-height: 100px;
  margin: auto;

  // z-index: 99;
}

// .customer-company-logo:hover {
// 	color: white;
// }

// --------------------------------------
//   Infocontainer for dashboard
// --------------------------------------
.info-container {
  background-color: lighten(colors.$dark-background, 5%);
  border-color: transparent;
  border-width: 0em;
  overflow-wrap: break-word;
  // overflow: hidden;
}

.info-container-header {
  background-color: inherit;
  font-weight: lighter;
}

// For table rows in mat-table
.table-row {
  cursor: pointer;
  transition: background-color 0.1s linear;
}

.table-row:hover {
  background-color: rgba(211, 211, 211, 0.1);
}

// ------------------------------------------------------------------------------------
// 				Changes to Angular/bootstrap elements
// ------------------------------------------------------------------------------------
.mat-form-search {
  background-color: lighten(colors.$dark-background, 5%);
  width: 36ch;
  padding-left: 24px;
  padding-right: 24px;
}

.mat-progress-bar {
  border-radius: 2px;
}

#mat-header {
  background-color: lighten(colors.$dark-background, 5%);
  padding-left: 12px;
  padding-right: 12px;
}

mat-dialog-container {
  height: auto !important;
}

.btn-primary {
  background-color: rgba(black, 0);
  color: var(--text-primary-color);
  border: 1px solid var(--text-primary-color);
}

.btn-primary:hover {
  background-color: var(--text-primary-color);
  color: colors.$dark-background;
}

.btn-danger {
  background-color: colors.$alarm-status !important;
  border-width: 0;
}

.btn-danger:hover {
  background-color: rgba(colors.$alarm-status, 0.9) !important;
}

.btn-danger:focus {
  background-color: rgba(colors.$alarm-status, 0.9) !important;
}

a {
  color: var(--accent-color);
}

a:visited {
  color: var(--accent-darker-color);
}

a:hover {
  color: var(--accent-color);
}

// $select-color: var(--accent-color);
$select-color: white;

// mat-input curser color
::ng-deep .mat-input-element {
  caret-color: $select-color !important;
}

// mat-label focused style
::ng-deep .mat-form-field.mat-focused .mat-form-field-label {
  color: $select-color !important;
}

::ng-deep .mat-form-field.mat-focused .mat-form-field-underline {
  background-color: $select-color !important;
  // display: none;
}

::ng-deep .mat-form-field-underline {
  color: $select-color;
}

::ng-deep .mat-form-field-underline .mat-form-field-ripple {
  background-color: $select-color;
}

::ng-deep .mat-form-field-underline {
  background-color: $select-color;
}

::ng-deep .mat-focused .mat-form-field-label {
  /*change color of label*/
  color: $select-color !important;
}

// For constant underline
// ::ng-deep.mat-form-field-underline {
//   /*change color of underline*/
//   background-color: $select-color !important;
// }

::ng-deep.mat-form-field-ripple {
  /*change color of underline when focused*/
  background-color: $select-color !important;
}

// Pagination
::ng-deep mat-option {
  background: COLOR1 !important;
  // color: white !important;
}

::ng-deep mat-option.mat-selected {
  background: COLOR2 !important;
  color: white !important;
}

::ng-deep .mat-select-arrow {
  color: white !important;
}

// -----------------
// Animations
// -----------------

.intro-animation {
  -webkit-animation: scale-up-center 0.4s cubic-bezier(0.895, 0.03, 0.685, 0.22)
    both;
  animation: scale-up-center 0.4s cubic-bezier(0.895, 0.03, 0.685, 0.22) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-5-4 13:52:16
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-up-center
 * ----------------------------------------
 */
@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

/*
CUSTOM GLOBAL CLASSES
*/

.dialog__no-padding mat-dialog-container {
  padding: 0;
}

// Overrides bootstrap blockquote
// Necessary for markdown blockquotes to be indented nicely (used in vibration report).

blockquote {
  margin: 1em 2em;
}
